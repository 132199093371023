import fuzzysort from "fuzzysort";

import getData from "@/__main__/get-data.mjs";
import type { RouteState } from "@/__main__/router.mjs";
import type { GameProfileSearchResults } from "@/data-models/search-players.mjs";
import GameProfileSearchModel from "@/data-models/search-players.mjs";
import { GAME_SYMBOL_DEADLOCK } from "@/game-deadlock/definition-symbol.mjs";
import { GAME_SYMBOL_UNKNOWN } from "@/game-unknown/definition-symbol.mjs";
import * as SEARCH_API from "@/search/api.mjs";
import {
  CACHE_KEY,
  GAME_SEARCH_COEFFICIENTS,
  SEARCH_QUERY,
} from "@/search/constants.mjs";
import { GAME_SEARCH } from "@/search/fetch-search-data.mjs";
import fetchSearchPlayersDeadlock from "@/search/fetch-search-players-deadlock.mjs";
import type {
  GameSearchProps,
  SearchPlayerTransform,
} from "@/search/types.d.mjs";
import { findGameSymbol, getCachedGameSymbol } from "@/util/game-route.mjs";
import { removeFromArray } from "@/util/helpers.mjs";
import retry from "@/util/retry-promise.mjs";
import SymbolMap from "@/util/symbol-map.mjs";

// Search for players in all games after a delay which is refreshed every call
// Please pass empty queries here as well to cancel the pending search from firing
export default async function searchPlayers(state: RouteState) {
  const { query, game, onComplete, count }: GameSearchProps =
    state[SEARCH_QUERY];

  const promoteGame = game || findGameSymbol() || getCachedGameSymbol();

  let games = game ? [game] : [...SymbolMap.keys(GAME_SEARCH)];
  games = games.filter((g) => GAME_SEARCH[g].players);
  removeFromArray(games, GAME_SYMBOL_UNKNOWN);

  let results = await Promise.all(
    games.map(async (g) => {
      const resp = await (
        g === GAME_SYMBOL_DEADLOCK
          ? fetchSearchPlayersDeadlock(query)
          : retry(
              () =>
                getData(
                  SEARCH_API.gameProfiles(query, g, count || 50),
                  GameProfileSearchModel,
                  undefined,
                  { acceptPartialErrors: false },
                ) as Promise<GameProfileSearchResults>,
              { maxRetries: 2 },
            )
      ).catch(
        () =>
          ({
            found: 0,
            hits: [],
            timer: {},
          }) as GameProfileSearchResults,
      );
      const results = resp.hits.reduce(
        (acc, result) => {
          acc.push({
            game: g,
            beScorePrimary: result.textMatchScore,
            beScoreSecondary: result.searchScore,
            [CACHE_KEY]: fuzzysort.prepare(result.name),
            ...GAME_SEARCH[g].players.transformResult(result),
          });
          return acc;
        },
        [] as Array<
          ReturnType<SearchPlayerTransform> & {
            game: symbol;
            beScorePrimary: number;
            beScoreSecondary: number;
            [CACHE_KEY]: ReturnType<typeof fuzzysort.prepare>;
          }
        >,
      );
      GAME_SEARCH[g].players?.onComplete?.(query, results);
      return Object.assign(results, {
        game: g,
        responseTimeMs: resp.timer.total || 0, // TODO @lyricwulf
      });
    }),
  );

  let responseTimeMs = 0;
  results = results.reduce((acc, result) => {
    responseTimeMs = Math.max(responseTimeMs, result.responseTimeMs);
    acc.push(...result);
    return acc;
  }, []);

  const selectScore = (a, b) => {
    if (a.beScorePrimary !== b.beScorePrimary)
      // higher is better
      return [a.beScorePrimary, b.beScorePrimary];

    if (a.beScoreSecondary !== b.beScoreSecondary)
      // higher is better
      return [a.beScoreSecondary, b.beScoreSecondary];

    // lower is better
    return [-a.name.length, -b.name.length];
  };
  // factor in game matches
  results = results.sort((a, b) => {
    let [aScore, bScore] = selectScore(a, b);

    const maxCoefficient = Object.keys(GAME_SEARCH_COEFFICIENTS).length + 1;
    const aGameCoefficient = !promoteGame
      ? GAME_SEARCH_COEFFICIENTS[a.game] || maxCoefficient
      : 1;
    const bGameCoefficient = !promoteGame
      ? GAME_SEARCH_COEFFICIENTS[b.game] || maxCoefficient
      : 1;

    if (a.game !== promoteGame) aScore /= 100 * aGameCoefficient;
    if (b.game !== promoteGame) bScore /= 100 * bGameCoefficient;

    if (aScore !== bScore) return bScore - aScore;

    return bScore - aScore;
  });

  // this hook architecture doesn't really play well with the new search
  // api so we can probably change this
  // more specifically i think the intent of this is to mock backend results
  // but at this stage they have already been transformed to our FE version
  // NOTE: I have disabled hook results for now. we should refactor this to
  // simply mock backend results for each shimmed game

  // results = await searchRefs.hookSearchResults(
  //   { query, game },
  //   { hits: results },
  // );

  onComplete(
    // @ts-expect-error idk
    Object.assign(results, {
      count: results.length,
      query,
      game,
      responseTimeMs,
    }),
  );
}

import { appURLs } from "@/app/constants.mjs";
import type { GameProfileSearchResults } from "@/data-models/search-players.mjs";
import type { SearchItem } from "@/data-models/search-players-v2.mjs";
import { SearchItemModel } from "@/data-models/search-players-v2.mjs";

export default async function fetchSearchPlayersDeadlock(
  query: string,
): Promise<GameProfileSearchResults> {
  const response = await fetch(`${appURLs.SEARCH_V2}/search/index/${query}`);
  const json = (await response.json()) as [[unknown, SearchItem]];
  const reduced: Array<SearchItem["x"]> = Object.values(
    json.reduce((acc, cur) => {
      for (const k in cur[1]) {
        const parsed = SearchItemModel(cur[1])[k];
        if (acc[parsed.steam_id]) continue;
        acc[parsed.steam_id] = parsed;
      }
      return acc;
    }, Object.create(null)),
  );
  const hits: GameProfileSearchResults["hits"] = reduced.map((i) => ({
    game: "deadlock",
    id: i.steam_id,
    metadata: { avatar: i.avatar },
    name: i.name,
    searchScore: 0,
    shard: "",
    textMatchScore: 0,
  }));
  return {
    found: hits.length,
    hits,
    timer: {
      endTimestamp: new Date(),
      postProcessing: 0,
      preProcessing: 0,
      searching: 0,
      startTimestamp: new Date(),
      total: 0,
    },
  };
}

import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped } from "@/__main__/data-model.mjs";

const SearchItem = Mapped({
  key: String,
  value: {
    avatar: String,
    name: String,
    steam_id: String,
  },
}) satisfies ModelDefinition;

export const SearchItemModel = createModel(SearchItem);

export type SearchItem = FromModel<typeof SearchItemModel>;
